var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "\n    To configure your new number for " +
            _vm._s(_vm.virtualPhoneToConfigure.company_name) +
            ",\n    choose an area code:\n    "
        ),
        _c("br"),
      ]),
      _c(
        "div",
        { staticClass: "jurisdiction-selection" },
        [
          _c("bandwidth-jurisdiction-select", {
            on: { input: _vm.setSelectedAreaCode },
          }),
        ],
        1
      ),
      _vm.isLoading ? _c("ct-centered-spinner") : _vm._e(),
      _vm.hasLoadedButNoNumbersWereFound
        ? _c("h6", { staticClass: "no-numbers-found-text" }, [
            _vm._v(
              "\n    No " +
                _vm._s(_vm.selectedAreaCode.code) +
                " area codes are available. Please select a different option.\n  "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }