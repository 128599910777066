<template>
  <div>
    <p>Numbers available in {{ selectedAreaCode.description }}</p>
    <div class="row mt-4">
      <ct-centered-spinner v-if="isLoading" />
      <div
        v-for="(phoneNum, index) in availableNumbers"
        v-else
        :key="`phoneNum-${index}`"
        class="col-lg-6 col-md-12 button-wrapper"
        @click="selectItem(phoneNum)"
      >
        <div>
          <b-form-radio
            v-model="selected"
            size="lg"
            class="phone-number-radio-button"
            :aria-describedby="phoneNum"
            name="phoneNumSelectionRadio"
            :value="phoneNum"
          >
            <div style="padding-left: 30px">
              <span v-phone-number="phoneNum" />
            </div>
          </b-form-radio>
        </div>
      </div>
    </div>
    <b-row style="display: flex; justify-content: space-between">
      <c-pagination
        :total-records="totalAvailablePhoneNumbers"
        :per-page="6"
        :current-page="1"
        :items="availableNumbers"
        @update:current-page="getNewPage"
      />
      <div>
        <b-button
          class="previous-button mr-1"
          variant="info"
          size="lg"
          style="height: 100%"
          @click="resetModalFields"
        >
          Previous
        </b-button>
        <b-button
          class="next-button"
          variant="info"
          size="lg"
          :disabled="!selected"
          @click="storeSelection"
        >
          Next
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'
import CPagination from '../../shared/Pagination'
import CtCenteredSpinner from './../../shared/CtCenteredSpinner'

export default {
  name: 'Step2',
  components: { CPagination, CtCenteredSpinner },
  directives: {
    phoneNumber,
  },

  data() {
    return {
      selected: null,
      selectedPage: 1,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'availableNumbersFinished',
      'selectedAreaCode',
      'availableNumbers',
      'totalAvailablePhoneNumbers',
    ]),
  },

  methods: {
    ...mapActions('virtualPhones', [
      'setSelectedAvailableNumber',
      'resetModalFields',
      'submitModalDecisions',
      'setAvailableNumbersPage',
      'fetchAvailableNumbers',
    ]),

    selectItem(selected) {
      this.selected = selected
    },

    async getNewPage(page) {
      try {
        this.isLoading = true
        this.setAvailableNumbersPage(page)
        await this.fetchAvailableNumbers()
        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    },

    async storeSelection() {
      try {
        this.isLoading = true
        await this.setSelectedAvailableNumber(this.selected)
        await this.submitModalDecisions()
        this.isLoading = false
      } catch {
        this.isLoading = false
        this.$bvToast.toast('The number you have selected may not be available. Please try again or select a different number', {
          title: 'Error',
          autoHideDelay: 5000,
          variant: 'danger',
        })
      }       
    },
  },
}
</script>

<style lang="scss">
.virtual-phone-setup-dialog {
  margin-top: 150px;

  .phone-setup-modal-body {
    margin: 50px 60px 30px;
    font-size: 21px;
    line-height: 32px;
    color: #47464b;
  }

  .button-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .next-button {
    margin-right: 10px;
    background-color: #3ebdbb;
    border-color: #3ebdbb;
    height: 60px;
  }

  .phone-number-radio-button {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    padding: 20px 20px 20px 40px;
    margin-bottom: 20px;

    .custom-control-label::before {
      margin-left: 15px;
      border: 1px solid #dbdbdb;
    }

    .custom-control-label::after {
      margin-left: 15px;
    }
  }
}
</style>
