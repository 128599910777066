<template>
  <b-modal
    id="virtual-phone-setup-modal-step-0"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
    dialog-class="virtual-phone-setup-dialog"
    body-class="phone-setup-modal-body"
    size="lg"
    footer-class="justify-content-center"
  >
    <div>
      <div>
        <div v-if="waitingOnPhones" class="pb-3">
          <b-row align-h="center" class="pb-0 mb-0">
            <p>Searching our labyrinth for your phones...</p>
          </b-row>
          <ct-centered-spinner />
        </div>
        <div v-else class="virtual-phone-mobile-flex-col">
          <div>
            <p>Let's set up your new phone line! Click <strong>Next</strong> to begin.</p>
          </div>
          <b-row class="virtual-phone-mobile-flex-col" align-h="end">
            <b-button
              class="previous-button mr-1"
              variant="info"
              size="lg"
              @click="closeModal"
            >
              Remind Me Later
            </b-button>
            <b-button
              v-if="loaded"
              aria-label="Next button"
              class="next-button"
              variant="info"
              size="lg"
              @click="next"
            >
              Next
            </b-button>
            <div v-else class="loader">
              <ct-centered-spinner height="75px" svg-height="75px" svg-width="75px" />
            </div>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: "Step0",

  components: { CtCenteredSpinner },
  props: {
    vm: Object,
  },
  data(){
    return {
      phones: [],
      waitingOnPhones: true,
      loaded: false,
      accountsStage: null,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'selectedAvailableNumber',
      'availableNumbers',
      'phoneName',
      'forwardToNumber',
      'forwardAs',
      'forwardType',
      'virtualPhonesToConfigure',
    ]),
    ...mapGetters('stagelineV3', [
      'accountsStages',
    ]),
  },

  async mounted() {
    this.waitingOnPhones = false
    this.loaded = false
    await this.loadAccountsStage()
    this.loading = true
    await this.setVirtualPhoneToConfigure()
  },

  methods: {
    ...mapActions('virtualPhones', [
      'loadVirtualPhones',
      'setVirtualPhonesToConfigure',
      'setVirtualPhoneToConfigure',
      'setVirtualPhoneConfigurationInProgress',
    ]),
    ...mapActions('stagelineV3', [
      'getAccountsStages',
    ]),

    async next() {
      if (this.accountsStage) {
        await this.closeModal()
        await this.$router.push({
          name: 'stage-viewer',
          params: {
            companyId: this.accountsStage.trackable_id,
            accountsStageId: this.accountsStage.id,
          },
          query: {
            return_to: 'phones',
          },
        })
      } else {
        await this.setVirtualPhoneConfigurationInProgress(true)
        this.$parent.$bvModal.show('virtual-phone-setup-modal')
        this.$parent.$bvModal.hide('virtual-phone-setup-modal-step-0')
      }
    },
    async closeModal() {
      await this.setVirtualPhoneConfigurationInProgress(false)
      this.$parent.$bvModal.hide('virtual-phone-setup-modal-step-0')
    },
    async loadAccountsStage() {
      try {
        const companyIds = this.virtualPhonesToConfigure.map(vp => vp.company_id)
        const response = await this.getAccountsStages({
          page: { number: 1, size: 1 },
          clear: true,
          filter: {
            stage_name_eq: 'set_up_phone_service',
            trackable_id_in: companyIds,
          },
          fields: ['id'],
        })
        this.accountsStage = response[0]
      } catch {
        /* Do nothing for now. */
      } finally {
        this.loaded = true
      }
    },
  },
}
</script>
<style lang="scss">
.loader {
  display: flex;
  width: 3em;
  align-items: center;
}
  @media only screen and (max-width: 660px) {
    .virtual-phone-mobile-flex-col {
      width: 100% !important;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      row-gap: 1.0em;
    }
  }
</style>
