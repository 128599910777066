var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "virtual-phone-setup-modal-step-0",
        "hide-header": true,
        "hide-footer": true,
        "no-close-on-backdrop": "",
        "dialog-class": "virtual-phone-setup-dialog",
        "body-class": "phone-setup-modal-body",
        size: "lg",
        "footer-class": "justify-content-center",
      },
    },
    [
      _c("div", [
        _c("div", [
          _vm.waitingOnPhones
            ? _c(
                "div",
                { staticClass: "pb-3" },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "pb-0 mb-0",
                      attrs: { "align-h": "center" },
                    },
                    [
                      _c("p", [
                        _vm._v("Searching our labyrinth for your phones..."),
                      ]),
                    ]
                  ),
                  _c("ct-centered-spinner"),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "virtual-phone-mobile-flex-col" },
                [
                  _c("div", [
                    _c("p", [
                      _vm._v("Let's set up your new phone line! Click "),
                      _c("strong", [_vm._v("Next")]),
                      _vm._v(" to begin."),
                    ]),
                  ]),
                  _c(
                    "b-row",
                    {
                      staticClass: "virtual-phone-mobile-flex-col",
                      attrs: { "align-h": "end" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "previous-button mr-1",
                          attrs: { variant: "info", size: "lg" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("\n            Remind Me Later\n          ")]
                      ),
                      _vm.loaded
                        ? _c(
                            "b-button",
                            {
                              staticClass: "next-button",
                              attrs: {
                                "aria-label": "Next button",
                                variant: "info",
                                size: "lg",
                              },
                              on: { click: _vm.next },
                            },
                            [_vm._v("\n            Next\n          ")]
                          )
                        : _c(
                            "div",
                            { staticClass: "loader" },
                            [
                              _c("ct-centered-spinner", {
                                attrs: {
                                  height: "75px",
                                  "svg-height": "75px",
                                  "svg-width": "75px",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }